<template>
  <div :class="['advanced-settings', { disabled: props.disabled }]">
    <div class="toggle" @click="toggleOpen" :class="{ disabled: props.disabled }">
      <span>{{ props.title }} <span v-if="props.isModified" class="is-modified-dot">●</span></span>
      <v-icon v-if="isOpen" icon="mdi-chevron-up" :class="{ disabled: props.disabled }"></v-icon>
      <v-icon v-else icon="mdi-chevron-down" :class="{ disabled: props.disabled }"></v-icon>
    </div>
    <div v-show="isOpen && !props.disabled" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "Advanced Settings",
  },
  disabled: {
    type: Boolean,
    default: false, 
  },
  isModified: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(false);

const toggleOpen = () => {
  if (!props.disabled) {
    isOpen.value = !isOpen.value;
  }
};

watch(
  () => props.disabled,
  (newVal) => {
    if (newVal) {
      isOpen.value = false;
    }
  }
);
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.advanced-settings {
  @include subtitleSmall;

  color: var(--knittable-lav-darker);

  padding-left: 5px;
  padding-right: 1.5rem;

  &.disabled {
    color: var(--knittable-lav-light);
    pointer-events: none;
  }
}

.toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: bold;

  &.disabled {
    cursor: not-allowed;
  }
}

.v-icon {
  &.disabled {
    color: var(--knittable-lav-light);
  }
}

.content {
  margin-top: 10px;
  display: grid;
  gap: 1.25rem;
}

.is-modified-dot {
  color: var(--knittable-opal);
  font-size: 1.2rem;
}
</style>
