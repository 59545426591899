<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("common.Mittens") }}</h3>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" class="app-pattern-form-col">
        <div class="app-form-description">{{ $t("mittens.pattern_description") }}</div>
        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false, true)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Measurements") }}
              </div>
            </header>
            <persons-select-field
              v-model="formData.chosenPerson"
              @item-selected="(item, oldItem) => onPersonSelected(item.value, oldItem?.value)"
            >
            </persons-select-field>
            <select-field
              v-if="loadedProject | (currentStep >= 1)"
              v-model="formData.chosenSize"
              ref="selectSizesField"
              :label="$t('common3.Size_')"
              :placeholder="$t('common3.Choose_a_size')"
              :items="sizes"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onSizeSelected(formData, item.value)"
            ></select-field>

            <advanced-settings
              v-if="loadedProject | (currentStep >= 2)"
              :title="t('common.Advanced_settings')"
              :is-modified="hasCustomAdvancedSettings"
            >
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.wristCircumf"
                  :label="$t('mittens.Cuff_circumference_in_cm_')"
                  :description="$t('mittens.Cuff_circumference_description')"
                  :placeholder="$t('mittens.Type_in_cuff_circumference')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeWristCircumf"
                  v-model:differs-from-baseline="differsFromBaseline.wristCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.cuffLength"
                  :label="$t('mittens.Cuff_length_in_cm_')"
                  :description="$t('mittens.Cuff_length_description')"
                  :placeholder="$t('mittens.Type_in_cuff_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeCuffLength"
                  v-model:differs-from-baseline="differsFromBaseline.cuffLength"
                ></input-field>
              </div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.handCircumf"
                  :label="$t('mittens.Hand_circumference_in_cm_')"
                  :description="$t('mittens.Hand_circumference_description')"
                  :placeholder="$t('mittens.Type_in_hand_circumference')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeHandCircumf"
                  v-model:differs-from-baseline="differsFromBaseline.handCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.handLength"
                  :label="$t('mittens.Hand_length_in_cm_')"
                  :description="$t('mittens.Hand_length_description')"
                  :placeholder="$t('mittens.Type_in_hand_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeHandLength"
                  v-model:differs-from-baseline="differsFromBaseline.handLength"
                ></input-field>
              </div>
            </advanced-settings>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 2)">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common4.Design") }}
              </div>
            </header>
            <ribbing-toggle-field v-model="formData.cuffPatternRepCount"> </ribbing-toggle-field>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 2)">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("mittens.Felting") }}
              </div>
              <information-bubble v-bind:colorsInverted="true" position="bottom" width="medium">
                <p>{{ $t("mittens.Felting_information_bubble_0") }}</p>
                <p>{{ $t("mittens.Felting_information_bubble_1") }}</p>
              </information-bubble>
            </header>
            <select-field
              v-model="formData.feltRatio"
              :label="$t('mittens.Felting_density_')"
              :placeholder="$t('mittens.Choose_how_much_to_felt')"
              :items="feltRatioOptions"
              :disabled="valuesDisabled"
              :small="true"
            ></select-field>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 3)">
            <knitting-gauge
              v-model:row-gauge="formData.rowGauge"
              v-model:stitch-gauge="formData.stitchGauge"
              v-model:estimate-row-gauge-enabled="formData.estimateRowGaugeEnabled"
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="newVersionAvailable">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true, true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
        >
        </pattern-form-button>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="showPattern">
        <PatternToolBar
          :title="$t('common.The_Knittable_mittens')"
          :disabled="buttonIsEnabled"
          :pattern-type="patternType"
          :description="$t('mittens.pattern_description')"
          :stitch-gauge="formData.stitchGauge"
          :row-gauge="formData.rowGauge"
          :properties="[
            { label: $t('common.Size_category'), value: formData.chosenPerson?.key },
            { label: $t('common.Size'), value: formData.chosenSize?.key },
          ]"
          :measurements="[
            {
              label: $t('mittens.Cuff_circumference_in_cm_'),
              value: formData.wristCircumf,
              differsFromBaseline: differsFromBaseline.wristCircumf,
            },
            {
              label: $t('mittens.Cuff_length_in_cm_'),
              value: formData.cuffLength,
              differsFromBaseline: differsFromBaseline.cuffLength,
            },
            {
              label: $t('mittens.Hand_circumference_in_cm_'),
              value: formData.handCircumf,
              differsFromBaseline: differsFromBaseline.handCircumf,
            },
            {
              label: $t('mittens.Hand_length_in_cm_'),
              value: formData.handLength,
              differsFromBaseline: differsFromBaseline.handLength,
            },
          ]"
          :design-details="[
            { label: $t('common.Ribbing_type'), value: formData.cuffPatternRepCount?.key },
            { label: $t('mittens.Felting_density_'), value: formData.feltRatio?.key },
          ]"
        >
          <pattern-renderer
            :current-version="currentVersion"
            :versions="versions"
            :disabled="buttonIsEnabled"
            v-model:bookmark="bookmark"
            :data="mittenData"
            :pattern-type="patternType"
            @update:bookmark="onBookmarkUpdated"
          ></pattern-renderer>
        </PatternToolBar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import AdvancedSettings from "../../components/AdvancedSettings";
import InformationBubble from "./../../components/InformationBubble.vue";
import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import { calculateMittens } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import {
  sizesSelectFieldDisabled,
  measurementFieldsDisabled,
  hasEmptyValues,
} from "../../lib/patternFormUtils";
import { loadCurrentProject, saveCurrentProject, saveBookmark } from "../../services/project";
import { useLangStore } from "./../../stores/lang";
import RibbingToggleField from "../../components/patterns/RibbingToggleField.vue";
import PatternToolBar from "../../components/PatternToolBar.vue";
const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();
const router = useRouter();

const patternType = "mitten";
const bookmark = ref(null);

const latestVersion = "1.1.0";
const currentVersion = ref(latestVersion);
const versions = {
  "1.0.0": "MittenPattern",
  "1.1.0": "MittenPattern_v1_1_0",
};

const { t } = useI18n();

const formData = ref({
  chosenPerson: null,
  chosenSize: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  wristCircumf: null,
  cuffLength: null,
  handCircumf: null,
  handLength: null,
  feltRatio: null,
  cuffPatternRepCount: { value: 2 }, // setting the default to single ribbing for backwards compatability reasons
});

const differsFromBaseline = ref({
  wristCircumf: false,
  cuffLength: false,
  handCircumf: false,
  handLength: false,
});

const hasCustomAdvancedSettings = ref(false);

watch(
  differsFromBaseline,
  (newValue) => {
    hasCustomAdvancedSettings.value = Object.values(newValue).some((value) => value);
  },
  { deep: true }
);

const selectSizesField = ref(null);
const sizes = ref(null);
let mittenData = ref(null);

const sizeSelectorDisabled = computed(() => sizesSelectFieldDisabled(formData.value));
const valuesDisabled = computed(() => measurementFieldsDisabled(formData.value));

const formHasChanges = ref(false);
const showPattern = ref(false);
const currentStep = ref(0);
const loadedProject = ref(false);

const isSubscribedOrOnTrial = ref(true);

watch(
  () => formData.value.chosenPerson,
  () => {
    switch (formData.value.chosenPerson.value) {
      case "child":
        sizes.value = sizeBabyChildYouth.value;
        break;
      case "grown_up":
        sizes.value = sizeGrownUp.value;
        break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(newChosenPerson, oldChosenPerson) {
  if (newChosenPerson == oldChosenPerson) {
    return;
  }
  if (newChosenPerson == "custom") {
    formData.value.chosenSize = "custom";
  } else {
    formData.value.chosenSize = null;
  }
  formData.value.wristCircumf = null;
  formData.value.cuffLength = null;
  formData.value.handCircumf = null;
  formData.value.handLength = null;

  currentStep.value = Math.max(currentStep.value, 1);
}

function onSizeSelected(formData, newChosenSize) {
  if (newChosenSize != null) {
    formData.wristCircumf = newChosenSize.sizeWristCircumf;
    formData.cuffLength = newChosenSize.sizeCuffLength;
    formData.handCircumf = newChosenSize.sizeHandCircumf;
    formData.handLength = newChosenSize.sizeHandLength;
  }
  
  currentStep.value = Math.max(currentStep.value, 2);
}

watch(
  () => formData.value.feltRatio,
  (value) => {

    currentStep.value = Math.max(currentStep.value, 3);
  }
);

onMounted(async () => {
  const project = await loadCurrentProject(patternType);
  if (project != null) {
    Object.assign(formData.value, project.settings);
    bookmark.value = project.bookmark;
    currentVersion.value = project.patternVersion;
    calculatePattern(false, false);
    loadedProject.value = true;
  }
});

const buttonIsEnabled = computed(() => {
  return formIsValid.value && formHasChanges.value;
});

const formIsValid = computed(() => {
  return !hasEmptyValues(formData.value);
});

const newVersionAvailable = computed(() => {
  return currentVersion.value != latestVersion;
});

const calculatePattern = async (upgrade, saveProject) => {
  try {
    loading.value = true;
    if (upgrade) {
      currentVersion.value = latestVersion;
    }
    if (formIsValid.value) {
      await getMittenParameters();
      formHasChanges.value = false;
      if (saveProject) {
        await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getMittenParameters() {
  const mittenParameters = await calculateMittens(
    formData.value.stitchGauge / 10,
    formData.value.rowGauge / 10,
    formData.value.wristCircumf,
    formData.value.cuffLength,
    formData.value.handCircumf,
    formData.value.handLength,
    formData.value.feltRatio.value,
    formData.value.cuffPatternRepCount.value,
    currentVersion.value
  );

  showPattern.value = true;
  mittenData.value = mittenParameters;
  return mittenParameters;
}

const goToSubscription = async () => {
  await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
  router.push(composeRoute(locale.value, "subscription", "mittens"));
};

watch(
  formData,
  () => {
    formHasChanges.value = true;
  },
  { deep: true }
);

const onBookmarkUpdated = async (newBookmark) => {
  bookmark.value = newBookmark;
  await saveBookmark(patternType, bookmark.value);
};

const sizeBabyChildYouth = computed(() => {
  return [
    {
      key: "0-3 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 10.0,
        sizeHandCircumf: 12.0,
        sizeHandLength: 7.5,
      },
    },
    {
      key: "3-6 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 10.5,
        sizeHandCircumf: 12.5,
        sizeHandLength: 8.0,
      },
    },
    {
      key: "6-9 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 10.5,
        sizeHandCircumf: 12.5,
        sizeHandLength: 8.5,
      },
    },
    {
      key: "9-12 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 11,
        sizeHandCircumf: 13,
        sizeHandLength: 9.0,
      },
    },
    {
      key: "1-2 " + t("common.years"),
      value: {
        sizeCuffLength: 4.0,
        sizeWristCircumf: 11.5,
        sizeHandCircumf: 13.5,
        sizeHandLength: 9.5,
      },
    },
    {
      key: "2-4 " + t("common.years"),
      value: {
        sizeCuffLength: 4.0,
        sizeWristCircumf: 12.0,
        sizeHandCircumf: 14.5,
        sizeHandLength: 10.5,
      },
    },
    {
      key: "4-6 " + t("common.years"),
      value: {
        sizeCuffLength: 4.0,
        sizeWristCircumf: 12.5,
        sizeHandCircumf: 15.0,
        sizeHandLength: 12.0,
      },
    },
    {
      key: "6-8 " + t("common.years"),
      value: {
        sizeCuffLength: 4.5,
        sizeWristCircumf: 13.0,
        sizeHandCircumf: 15.5,
        sizeHandLength: 12.5,
      },
    },
    {
      key: "8-10 " + t("common.years"),
      value: {
        sizeCuffLength: 4.5,
        sizeWristCircumf: 13.5,
        sizeHandCircumf: 16.0,
        sizeHandLength: 14.5,
      },
    },
    {
      key: "10-12 " + t("common.years"),
      value: {
        sizeCuffLength: 4.5,
        sizeWristCircumf: 14.5,
        sizeHandCircumf: 17.0,
        sizeHandLength: 16.0,
      },
    },
  ];
});

const sizeGrownUp = computed(() => {
  return [
    {
      key: "XS",
      value: {
        sizeCuffLength: 6.0,
        sizeWristCircumf: 14.5,
        sizeHandCircumf: 17.0,
        sizeHandLength: 17.0,
      },
    },
    {
      key: "S",
      value: {
        sizeCuffLength: 6.5,
        sizeWristCircumf: 15.0,
        sizeHandCircumf: 18.0,
        sizeHandLength: 18.0,
      },
    },
    {
      key: "M",
      value: {
        sizeCuffLength: 6.5,
        sizeWristCircumf: 16.5,
        sizeHandCircumf: 19.5,
        sizeHandLength: 19.0,
      },
    },
    {
      key: "L",
      value: {
        sizeCuffLength: 7.0,
        sizeWristCircumf: 18.0,
        sizeHandCircumf: 21.0,
        sizeHandLength: 20.0,
      },
    },
    {
      key: "XL",
      value: {
        sizeCuffLength: 7.0,
        sizeWristCircumf: 20.5,
        sizeHandCircumf: 24.0,
        sizeHandLength: 21.5,
      },
    },
  ];
});
const feltRatioOptions = computed(() => {
  return [
    { key: t("mittens.No"), value: 1 },
    { key: t("mittens.Felt_a_little"), value: 1.1 },
    { key: t("mittens.Felt_somewhat"), value: 1.2 },
    { key: t("mittens.Felt_a_lot"), value: 1.3 },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
