<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("common.Sweater") }}</h3>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" class="app-pattern-col app-pattern-form-col">
        <!-- TODO: Remove this once all active sweater patterns have been updated -->
        <div class="app-form-notification app-warning" v-if="showWarningOnUpdatedPattern">
          {{ $t("sweater.Pattern_updated_warning", { email: "hello@knittable.com" }) }}
        </div>

        <div class="app-form-notification app-warning" v-else>
          {{ $t("sweater.Pattern_test_knit_warning", { email: "hello@knittable.com" }) }}
        </div>

        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false, true)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Size") }}
              </div>
            </header>
            <tooltip
              position="bottom"
              width="medium"
              :colors-inverted="true"
              :is-enabled="
                formData.chosenFit != null ||
                formData.chosenLength != null ||
                formData.chosenSize != null
              "
            >
              <template v-slot:content>
                <persons-select-field
                  v-model="formData.chosenPerson"
                  :include-gendered-options="true"
                  @item-selected="(item, oldItem) => onPersonSelected(item.value, oldItem?.value)"
                >
                </persons-select-field>
              </template>
              <template v-slot:tooltip>{{ $t("sweater.Change_person_warning") }}</template>
            </tooltip>

            <select-field
              v-if="loadedProject | (currentStep >= 1)"
              v-model="formData.chosenSize"
              ref="selectSizesField"
              :label="t('common3.Size_')"
              :description="
                formData.chosenPerson.value === 'child' ? null : t('sweater.Size_description')
              "
              :placeholder="t('common3.Choose_a_size')"
              :items="sizes"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onSizeSelected(formData, item.value)"
            ></select-field>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 2)">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("sweater.Shape") }}
              </div>
            </header>

            <select-field
              v-if="loadedProject | (currentStep >= 2)"
              v-model="formData.chosenFit"
              :label="t('sweater.Fit')"
              :placeholder="t('sweater.Choose_fit')"
              :items="fitOptions"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onFitSelected(formData, item.value)"
            ></select-field>

            <select-field
              v-if="loadedProject | (currentStep >= 3)"
              v-model="formData.chosenLength"
              :label="t('sweater.Length')"
              :placeholder="t('sweater.Choose_length')"
              :items="lengthOptions"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onLengthSelected(formData, item.value)"
            ></select-field>

            <advanced-settings
              v-if="loadedProject | (currentStep >= 4)"
              :title="t('common.Advanced_settings')"
              :disabled="!showAdvancedSettings"
              :is-modified="hasCustomAdvancedSettings"
            >
              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Neckband") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.neckbandCircumf"
                  :label="t('sweater.Neckband_circumf_in_cm_')"
                  :description="t('sweater.Neckband_circumf_description')"
                  :placeholder="t('sweater.Type_in_neckband_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeNeckbandCircumf"
                  v-model:differsFromBaseline="differsFromBaseline.neckbandCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.neckbandLength"
                  :label="t('sweater.Neckband_length_in_cm_')"
                  :description="t('sweater.Neckband_length_description')"
                  :placeholder="t('sweater.Type_in_neckband_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeNeckbandLength"
                  v-model:differsFromBaseline="differsFromBaseline.neckbandLength"
                ></input-field>
              </div>

              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Body") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.chestCircumf"
                  :label="t('sweater.Chest_circumf')"
                  :description="t('sweater.Chest_circumf_description')"
                  :placeholder="t('sweater.Type_in_chest_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeChestCircumf"
                  v-model:differsFromBaseline="differsFromBaseline.chestCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.easeBody"
                  :label="t('sweater.Body_ease')"
                  :description="t('sweater.Body_ease_description')"
                  :placeholder="t('sweater.Type_in_body_ease')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :allow-zero="true"
                  :min-value="-5"
                  :max-value="40"
                  :baseline-value="formData.chosenFit?.value.easeBody"
                  v-model:differsFromBaseline="differsFromBaseline.easeBody"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.finishedChestCircumf"
                  :label="t('sweater.Finished_chest_circumf')"
                  :description="t('sweater.Finished_chest_circumf_description')"
                  :disabled="true"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.hemLength"
                  :label="t('sweater.Hem_length_in_cm_')"
                  :description="t('sweater.Hem_length_description')"
                  :placeholder="t('sweater.Type_in_hem_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeHemLength"
                  v-model:differsFromBaseline="differsFromBaseline.hemLength"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.sweaterLength"
                  :label="t('sweater.Sweater_length_in_cm_')"
                  :description="t('sweater.Sweater_length_description')"
                  :placeholder="t('sweater.Type_in_sweater_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="calculatedChosenSweaterLength"
                  v-model:differsFromBaseline="differsFromBaseline.sweaterLength"
                ></input-field>
              </div>

              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Sleeves") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.armCircumf"
                  :label="t('sweater.Arm_circumf')"
                  :description="t('sweater.Arm_circumf_description')"
                  :placeholder="t('sweater.Type_in_arm_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeArmCircumf"
                  v-model:differsFromBaseline="differsFromBaseline.armCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.easeSleeve"
                  :label="t('sweater.Sleeve_ease')"
                  :description="t('sweater.Sleeve_ease_description')"
                  :placeholder="t('sweater.Type_in_sleeve_ease')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :allow-zero="true"
                  :min-value="-5"
                  :max-value="40"
                  :baseline-value="formData.chosenFit?.value.easeSleeve"
                  v-model:differsFromBaseline="differsFromBaseline.easeSleeve"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.sleeveCircumf"
                  :label="t('sweater.Sleeve_circumf')"
                  :description="t('sweater.Sleeve_circumf_description')"
                  :placeholder="t('sweater.Type_in_sleeve_circumf')"
                  :disabled="true"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.armLength"
                  :label="t('sweater.Arm_length_in_cm_')"
                  :description="t('sweater.Arm_length_description')"
                  :placeholder="t('sweater.Type_in_arm_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeArmLength"
                  v-model:differsFromBaseline="differsFromBaseline.armLength"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.wristCircumf"
                  :label="t('sweater.Wrist_circumf')"
                  :description="t('sweater.Wrist_circumf_description')"
                  :placeholder="t('sweater.Type_in_wrist_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="formData.chosenSize?.value.sizeWristCircumf"
                  v-model:differsFromBaseline="differsFromBaseline.wristCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.cuffLength"
                  :label="t('sweater.Cuff_length_in_cm_')"
                  :description="t('sweater.Cuff_length_description')"
                  :placeholder="t('sweater.Type_in_cuff_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :step="1"
                  :baseline-value="formData.chosenSize?.value.sizeCuffLength"
                  v-model:differsFromBaseline="differsFromBaseline.cuffLength"
                ></input-field>
              </div>
            </advanced-settings>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 4)">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common4.Design") }}
              </div>
            </header>
            <toggle-field
              :label="t('sweater.Neckband_type')"
              :options="neckbandTypeOptions"
              v-model="formData.neckbandType"
              v-if="loadedProject | (currentStep >= 4)"
            ></toggle-field>

            <ribbing-toggle-field
              v-model="formData.patternRibStitchCount"
              v-if="loadedProject | (currentStep >= 4)"
            >
            </ribbing-toggle-field>
            <div class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.raglanStitchCount"
                :label="t('sweater.Raglan_stitch_count')"
                :description="t('sweater.Raglan_stitch_count_description')"
                :placeholder="t('sweater.Type_in_raglan_stitch_count')"
                :disabled="valuesDisabled"
                :small="true"
                :step="1"
              ></input-field>
              <v-spacer></v-spacer>
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.patternBodyStitchCount"
                :label="t('sweater.Body_pattern_repeat')"
                :description="t('sweater.Body_pattern_repeat_description')"
                :placeholder="t('sweater.Type_in_body_pattern_repeat')"
                :disabled="valuesDisabled"
                :small="true"
              ></input-field>
            </div>

            <checkbox-field v-model="formData.applyNeckShaping">{{
              $t("sweater.Shape_neck_with_short_rows")
            }}</checkbox-field>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 5)">
            <knitting-gauge
              v-model:row-gauge="formData.rowGauge"
              v-model:stitch-gauge="formData.stitchGauge"
              v-model:estimate-row-gauge-enabled="formData.estimateRowGaugeEnabled"
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="newVersionAvailable">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true, true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
        >
        </pattern-form-button>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="app-pattern-col" v-if="errorMessage">
        <div cols="12" sm="12" md="6" class="app-form-notification app-error">
          {{ errorMessage }}
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="app-pattern-col" v-else-if="showPattern">
        <PatternToolBar
          :title="$t('common.The_Knittable_sweater')"
          :disabled="buttonIsEnabled"
          :pattern-type="patternType"
          :stitch-gauge="formData.stitchGauge"
          :row-gauge="formData.rowGauge"
          :properties="[
            { label: $t('common.Size_category'), value: formData.chosenPerson?.key },
            { label: $t('common.Size'), value: formData.chosenSize?.key },
            { label: $t('sweater.Fit'), value: formData.chosenFit?.key },
            { label: $t('sweater.Length'), value: formData.chosenLength?.key },
          ]"
          :measurements="[
            {
              label: $t('sweater.Neckband_circumf_in_cm_'),
              value: formData.neckbandCircumf,
              differsFromBaseline: differsFromBaseline.neckbandCircumf,
            },
            {
              label: $t('sweater.Neckband_length_in_cm_'),
              value: formData.neckbandLength,
              differsFromBaseline: differsFromBaseline.neckbandLength,
            },
            {
              label: $t('sweater.Chest_circumf'),
              value: formData.chestCircumf,
              differsFromBaseline: differsFromBaseline.chestCircumf,
            },
            {
              label: $t('sweater.Body_ease'),
              value: formData.easeBody,
              differsFromBaseline: differsFromBaseline.easeBody,
            },
            {
              label: $t('sweater.Hem_length_in_cm_'),
              value: formData.hemLength,
              differsFromBaseline: differsFromBaseline.hemLength,
            },
            {
              label: $t('sweater.Sweater_length_in_cm_'),
              value: formData.sweaterLength,
              differsFromBaseline: differsFromBaseline.sweaterLength,
            },
            {
              label: $t('sweater.Arm_circumf'),
              value: formData.armCircumf,
              differsFromBaseline: differsFromBaseline.armCircumf,
            },
            {
              label: $t('sweater.Sleeve_ease'),
              value: formData.easeSleeve,
              differsFromBaseline: differsFromBaseline.easeSleeve,
            },
            {
              label: $t('sweater.Arm_length_in_cm_'),
              value: formData.armLength,
              differsFromBaseline: differsFromBaseline.armLength,
            },
            {
              label: $t('sweater.Wrist_circumf'),
              value: formData.wristCircumf,
              differsFromBaseline: differsFromBaseline.wristCircumf,
            },
            {
              label: $t('sweater.Cuff_length_in_cm_'),
              value: formData.cuffLength,
              differsFromBaseline: differsFromBaseline.cuffLength,
            },
          ]"
          :design-details="[
            { label: $t('sweater.Neckband_type'), value: formData.neckbandType?.key },
            { label: $t('common.Ribbing_type'), value: formData.patternRibStitchCount?.key },
            { label: $t('sweater.Raglan_stitch_count'), value: formData.raglanStitchCount },
            { label: $t('sweater.Body_pattern_repeat'), value: formData.patternBodyStitchCount },
          ]"
        >
          <pattern-renderer
            :current-version="currentVersion"
            :versions="versions"
            :disabled="buttonIsEnabled"
            v-model:bookmark="bookmark"
            :data="sweaterData"
            @update:bookmark="onBookmarkUpdated"
            :pattern-type="patternType"
          ></pattern-renderer>
        </PatternToolBar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import AdvancedSettings from "../../components/AdvancedSettings";
import CheckboxField from "../../components/general/CheckboxField.vue";
import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import { calculateSweater } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import {
  sizesSelectFieldDisabled,
  measurementFieldsDisabled,
  hasEmptyValues,
} from "../../lib/patternFormUtils";
import { loadCurrentProject, saveCurrentProject, saveBookmark } from "../../services/project";
import { useLangStore } from "./../../stores/lang";
import { roundToBase } from "./../../lib/calculationUtils.js";
import RibbingToggleField from "../../components/patterns/RibbingToggleField.vue";
import ToggleField from "../../components/general/ToggleField.vue";
import PatternToolBar from "../../components/PatternToolBar.vue";
import Tooltip from "../../components/Tooltip.vue";

const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();

const router = useRouter();

const patternType = "sweater";
const bookmark = ref(null);

const latestVersion = "0.1.1";
const currentVersion = ref(latestVersion);
const versions = {
  "0.1.0": "SweaterPattern",
  "0.1.1": "SweaterPattern_v0_1_1",
};

const { t } = useI18n();

const formData = ref({
  chosenPerson: null,
  chosenSize: null,
  chosenFit: null,
  chosenLength: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  finishedChestCircumf: null,
  chestCircumf: null,
  easeBody: null,
  cuffLength: null,
  wristCircumf: null,
  neckbandCircumf: null,
  neckbandLength: null,
  sleeveCircumf: null,
  armCircumf: null,
  easeSleeve: null,
  armLength: null,
  hemLength: null,
  patternRibStitchCount: null,
  raglanStitchCount: null,
  patternBodyStitchCount: 1,
  yokeType: "raglan",
  sweaterLength: null,
  sleeveType: "regular",
  applyNeckShaping: true,
  neckbandType: null,
});

const differsFromBaseline = ref({
  neckbandCircumf: false,
  neckbandLength: false,
  chestCircumf: false,
  easeBody: false,
  hemLength: false,
  sweaterLength: false,
  armCircumf: false,
  easeSleeve: false,
  armLength: false,
  wristCircumf: false,
  cuffLength: false,
});

const hasCustomAdvancedSettings = ref(false);

watch(
  differsFromBaseline,
  (newValue) => {
    hasCustomAdvancedSettings.value = Object.values(newValue).some((value) => value);
  },
  { deep: true }
);

const selectSizesField = ref(null);
const sizes = ref(null);
let sweaterData = ref(null);

const sizeSelectorDisabled = computed(() => sizesSelectFieldDisabled(formData.value));
const valuesDisabled = computed(() => measurementFieldsDisabled(formData.value));

const formHasChanges = ref(false);
const showPattern = ref(false);
const currentStep = ref(0);
const loadedProject = ref(false);

const isSubscribedOrOnTrial = ref(true);

const errorMessage = ref(null);
const showWarningOnUpdatedPattern = ref(false);

const calculatedChosenSweaterLength = ref(null);

watch(
  () => formData.value.chosenPerson,
  () => {
    switch (formData.value.chosenPerson.value) {
      case "child":
        sizes.value = sizeBabyChildYouth.value;
        break;
      // case "man":
      //   sizes.value = sizeMan.value;
      //   break;
      case "woman":
        sizes.value = sizeWomen.value;
        break;
      // case "unisex":
      //   sizes.value = sizeUnisex.value;
      //   break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(newChosenPerson, oldChosenPerson) {
  if (newChosenPerson == oldChosenPerson) {
    return;
  }

  if (newChosenPerson == "custom") {
    formData.value.chosenSize = "custom";
  } else {
    formData.value.chosenSize = null;
  }

  resetSizes();
  formData.value.chosenFit = null;
  formData.value.chosenLength = null;

  currentStep.value = Math.max(currentStep.value, 1);
}

const resetSizes = () => {
  formData.value.neckbandCircumf = null;
  formData.value.chestCircumf = null;
  formData.value.armCircumf = null;
  formData.value.neckbandLength = null;
  formData.value.cuffLength = null;
  formData.value.hemLength = null;
};

function calculateSweaterLength(chosenLength, chestCircumf) {
  switch (chosenLength) {
    case "cropped":
      return calculateCroppedLength(chestCircumf);
    case "regular":
      return calculateRegularLength(chestCircumf);
    case "long":
      return calculateLongLength(chestCircumf);
    default:
      return null;
  }
}

watch(
  () => [formData.value.chosenLength, formData.value.chestCircumf],
  () => {
    if (formData.value.chosenLength?.value == null || formData.value.chestCircumf == null) {
      calculatedChosenSweaterLength.value = null;
    } else {
      calculatedChosenSweaterLength.value = calculateSweaterLength(
        formData.value.chosenLength.value,
        formData.value.chestCircumf
      );
    }
  }
);

function onSizeSelected(formData, newChosenSize) {
  formData.neckbandCircumf = newChosenSize.sizeNeckbandCircumf;
  formData.chestCircumf = newChosenSize.sizeChestCircumf;
  formData.armCircumf = newChosenSize.sizeArmCircumf;
  formData.armLength = newChosenSize.sizeArmLength;
  formData.wristCircumf = newChosenSize.sizeWristCircumf;
  formData.neckbandLength = newChosenSize.sizeNeckbandLength;
  formData.cuffLength = newChosenSize.sizeCuffLength;
  formData.hemLength = newChosenSize.sizeHemLength;

  if (formData.chosenLength && formData.chosenLength.value) {
    // Realculate sweater length based on the chosen length
    formData.sweaterLength = calculateSweaterLength(
      formData.chosenLength.value,
      formData.chestCircumf
    );
  }

  currentStep.value = Math.max(currentStep.value, 2);
}

function onFitSelected(formData, newChosenFit) {
  formData.easeBody = newChosenFit.easeBody;
  formData.easeSleeve = newChosenFit.easeSleeve;

  currentStep.value = Math.max(currentStep.value, 3);
}

function onLengthSelected(formData, newChosenLength) {
  // Calculate sweater length based on the chosen length
  formData.sweaterLength = calculateSweaterLength(
    newChosenLength,
    formData.chosenSize.value.sizeChestCircumf
  );

  currentStep.value = Math.max(currentStep.value, 4);
}

onMounted(async () => {
  const project = await loadCurrentProject(patternType);
  if (project != null) {
    if (!project.settings.applyNeckShaping) {
      // Neck shaping is not supported in the old patterns
      project.settings.applyNeckShaping = false;
    }
    project.settings.neckbandType = project.settings.neckbandType || { value: "folded" };
    showWarningOnUpdatedPattern.value = isOutdated(project.createdAt, project.updatedAt);
    Object.assign(formData.value, project.settings);
    bookmark.value = project.bookmark;
    currentVersion.value = project.patternVersion;
    calculatePattern(false, false);
    loadedProject.value = true;
  }
});

const isOutdated = (createdAt, updatedAt) => {
  const lastSavedDate = updatedAt ? new Date(updatedAt) : new Date(createdAt);
  const comparisonDate = new Date("2024-10-15T15:00:00");
  return lastSavedDate < comparisonDate;
};

const buttonIsEnabled = computed(() => {
  return formIsValid.value && formHasChanges.value;
});

const formIsValid = computed(() => {
  const validFormData = { ...formData.value };

  // easeBody and easeSleeve are allowed to be 0
  if (validFormData.easeBody === 0) {
    delete validFormData.easeBody;
  }
  if (validFormData.easeSleeve === 0) {
    delete validFormData.easeSleeve;
  }

  return !hasEmptyValues(validFormData);
});

const newVersionAvailable = computed(() => {
  return currentVersion.value != latestVersion;
});

const calculatePattern = async (upgrade, saveProject) => {
  try {
    loading.value = true;
    if (upgrade) {
      currentVersion.value = latestVersion;
    }
    if (formIsValid.value) {
      await getSweaterParameters();
      formHasChanges.value = false;
      if (saveProject) {
        await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
        showWarningOnUpdatedPattern.value = false;
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getSweaterParameters() {
  try {
    const sweaterParameters = await calculateSweater(
      formData.value.stitchGauge / 10,
      formData.value.rowGauge / 10,
      formData.value.chestCircumf,
      formData.value.easeBody,
      formData.value.wristCircumf,
      formData.value.cuffLength,
      formData.value.neckbandCircumf,
      formData.value.neckbandLength,
      formData.value.armCircumf,
      formData.value.armLength,
      formData.value.easeSleeve,
      formData.value.patternRibStitchCount.value,
      formData.value.raglanStitchCount,
      formData.value.patternBodyStitchCount,
      formData.value.yokeType,
      formData.value.sweaterLength,
      formData.value.hemLength,
      formData.value.sleeveType,
      formData.value.applyNeckShaping,
      formData.value.neckbandType.value,
      currentVersion.value
    );

    if (sweaterParameters.warnings.length > 0) {
      sweaterParameters.warnings = sweaterParameters.warnings.map(
        (warning) => warningTranslation[warning] || "sweater.default_warning"
      );
    }

    showPattern.value = true;
    errorMessage.value = null;
    sweaterData.value = sweaterParameters;
    return sweaterParameters;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      showPattern.value = false;
      errorMessage.value = errorTranslation(error.response.data.detail);
    } else {
      throw error;
    }
  }
}

const warningTranslation = {
  SINGLE_RIBBING_RAGLAN_STITCH_COUNT_MISMATCH: t(
    "sweater.Single_ribbing_raglan_stitch_count_mismatch"
  ),
  DOUBLE_RIBBING_RAGLAN_STITCH_COUNT_MISMATCH: t(
    "sweater.Double_ribbing_raglan_stitch_count_mismatch"
  ),
};

const errorTranslation = (errorData) => {
  switch (errorData.code) {
    case "RAGLAN_TOO_WIDE":
      return t("sweater.Raglan_too_wide", {
        maxRaglanStitchCount: errorData.extra_data["maximum_allowed"],
      });
    case "BODY_SLEEVE_WIDTH_DISCREPANCY":
      if (errorData.extra_data["part"] == "body") {
        return t("sweater.Body_not_wide_enough");
      } else {
        return t("sweater.Sleeve_not_wide_enough");
      }
    case "NECK_SHAPING_FAILED":
      return t("sweater.Neck_shaping_failed");
    default:
      return t("sweater.default_error");
  }
};

const goToSubscription = async () => {
  await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
  router.push(composeRoute(locale.value, "subscription", "sweater"));
};

watch(
  formData,
  () => {
    formHasChanges.value = true;
  },
  { deep: true }
);

const onBookmarkUpdated = async (newBookmark) => {
  bookmark.value = newBookmark;
  await saveBookmark(patternType, bookmark.value);
};

const calculateCroppedLength = (chestCircumf) => {
  // based on sweater length from several cropped sweater patterns
  return formData.value.chosenPerson.value == "child"
    ? roundToBase(-93 + 31.5 * Math.log(chestCircumf), 0.5)
    : roundToBase(0.25 * chestCircumf + 30, 0.5);
};

const calculateRegularLength = (chestCircumf) => {
  // based on sweater length from several regular sweater patterns
  return formData.value.chosenPerson.value == "child"
    ? roundToBase(-129 + 42 * Math.log(chestCircumf), 0.5)
    : roundToBase(0.28 * chestCircumf + 35, 0.5);
};

const calculateLongLength = (chestCircumf) => {
  // based on sweater length from several long sweater patterns
  return formData.value.chosenPerson.value == "child"
    ? roundToBase(-186 + 57.8 * Math.log(chestCircumf), 0.5)
    : roundToBase(0.123 * chestCircumf + 66, 0.5);
};

const personSizeIsSelected = computed(() => {
  return formData.value.chosenPerson != null && formData.value.chosenPerson.value != "custom";
});

const customSizeIsSelected = computed(() => {
  return formData.value.chosenPerson != null && formData.value.chosenPerson.value == "custom";
});

const showAdvancedSettings = computed(() => {
  if (customSizeIsSelected.value) {
    return true;
  }
  if (
    personSizeIsSelected &&
    formData.value.chosenSize != null &&
    formData.value.chosenFit != null &&
    formData.value.chosenLength != null
  ) {
    return true;
  }

  return false;
});

watch(
  () => formData.value.patternRibStitchCount,
  (value) => {
    // TODO: Add a warning if raglan stitch count and pattern rib
    // stitch count do not go together
    if (!formData.value.raglanStitchCount) {
      formData.value.raglanStitchCount = value.value == 2 ? 1 : 2;
    }
    currentStep.value = Math.max(currentStep.value, 5);
  }
);

watch(
  () => [formData.value.chestCircumf, formData.value.easeBody],
  ([newChestCircumf, newEaseBody]) => {
    if (newChestCircumf == null || newEaseBody == null) {
      formData.value.finishedChestCircumf = null;
    } else {
      formData.value.finishedChestCircumf = newChestCircumf + newEaseBody;
    }
  }
);

watch(
  () => [formData.value.armCircumf, formData.value.easeSleeve],
  ([newArmCircumf, newEaseSleeve]) => {
    if (newArmCircumf == null || newEaseSleeve == null) {
      formData.value.sleeveCircumf = null;
    } else {
      formData.value.sleeveCircumf = newArmCircumf + newEaseSleeve;
    }
  }
);

watch(
  () => [formData.value.chestCircumf, formData.value.easeBody],
  ([newChestCircumf, newEaseBody]) => {
    formData.value.finishedChestCircumf = newChestCircumf + newEaseBody;
  }
);

watch(
  () => [formData.value.armCircumf, formData.value.easeSleeve],
  ([newArmCircumf, newEaseSleeve]) => {
    formData.value.sleeveCircumf = newArmCircumf + newEaseSleeve;
  }
);

const fitOptions = computed(() => {
  if (formData.value.chosenPerson?.value === "child") {
    return [
      {
        key: t("sweater.Fitted") + " (-3 cm ease)",
        value: {
          easeBody: -3,
          easeSleeve: -1,
        },
      },
      {
        key: t("sweater.Tailored") + " (0 cm ease)",
        value: {
          easeBody: 0,
          easeSleeve: 0,
        },
      },
      {
        key: t("sweater.Relaxed") + " (5 cm ease)",
        value: {
          easeBody: 5,
          easeSleeve: 3,
        },
      },
      {
        key: t("sweater.Loose") + " (10 cm ease)",
        value: {
          easeBody: 10,
          easeSleeve: 4,
        },
      },
      {
        key: t("sweater.Oversized") + " (20 cm ease)",
        value: {
          easeBody: 20,
          easeSleeve: 7,
        },
      },
    ];
  }

  // Default fit options for adults
  return [
    {
      key: t("sweater.Fitted") + " (-5 cm ease)",
      value: {
        easeBody: -5,
        easeSleeve: -3.5,
      },
    },
    {
      key: t("sweater.Tailored") + " (0 cm ease)",
      value: {
        easeBody: 0,
        easeSleeve: 0,
      },
    },
    {
      key: t("sweater.Relaxed") + " (5 cm ease)",
      value: {
        easeBody: 5,
        easeSleeve: 3.5,
      },
    },
    {
      key: t("sweater.Loose") + " (15 cm ease)",
      value: {
        easeBody: 15,
        easeSleeve: 10,
      },
    },
    {
      key: t("sweater.Oversized") + " (25 cm ease)",
      value: {
        easeBody: 25,
        easeSleeve: 18,
      },
    },
  ];
});
const lengthOptions = computed(() => {
  return [
    {
      key: t("sweater.Cropped"),
      value: "cropped",
    },
    {
      key: t("sweater.Regular"),
      value: "regular",
    },
    {
      key: t("sweater.Long"),
      value: "long",
    },
  ];
});

const neckbandTypeOptions = computed(() => {
  return [
    { key: t("sweater.Plain"), value: "plain" },
    { key: t("sweater.Folded"), value: "folded" },
  ];
});

const sizeBabyChildYouth = computed(() => {
  return [
    {
      key: "0 - 3 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 25,
        sizeArmCircumf: 15,
        sizeWristCircumf: 10,
        sizeChestCircumf: 42,
        sizeArmLength: 17,
        sizeNeckbandLength: 2,
        sizeCuffLength: 3,
        sizeHemLength: 3,
      },
    },
    {
      key: "3 - 6 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 26.5,
        sizeArmCircumf: 16,
        sizeWristCircumf: 10.5,
        sizeChestCircumf: 45,
        sizeArmLength: 18,
        sizeNeckbandLength: 2,
        sizeCuffLength: 3,
        sizeHemLength: 3,
      },
    },
    {
      key: "6 - 9 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 28,
        sizeArmCircumf: 17,
        sizeWristCircumf: 10.5,
        sizeChestCircumf: 47,
        sizeArmLength: 19,
        sizeNeckbandLength: 2.5,
        sizeCuffLength: 3.5,
        sizeHemLength: 3.5,
      },
    },
    {
      key: "9 - 12 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 29.5,
        sizeArmCircumf: 17.5,
        sizeWristCircumf: 11,
        sizeChestCircumf: 49,
        sizeArmLength: 21,
        sizeNeckbandLength: 2.5,
        sizeCuffLength: 3.5,
        sizeHemLength: 3.5,
      },
    },
    {
      key: "1 - 2 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 31,
        sizeArmCircumf: 18,
        sizeWristCircumf: 11.5,
        sizeChestCircumf: 51,
        sizeArmLength: 23,
        sizeNeckbandLength: 3,
        sizeCuffLength: 4,
        sizeHemLength: 4,
      },
    },
    {
      key: "2 - 4 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 32.5,
        sizeArmCircumf: 19.5,
        sizeWristCircumf: 12.0,
        sizeChestCircumf: 54,
        sizeArmLength: 28,
        sizeNeckbandLength: 3,
        sizeCuffLength: 4,
        sizeHemLength: 4,
      },
    },
    {
      key: "4 - 6 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 34,
        sizeArmCircumf: 21,
        sizeWristCircumf: 12.5,
        sizeChestCircumf: 59,
        sizeArmLength: 32,
        sizeNeckbandLength: 3.5,
        sizeCuffLength: 4.5,
        sizeHemLength: 4.5,
      },
    },
    {
      key: "6 - 8 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 35,
        sizeArmCircumf: 22,
        sizeWristCircumf: 13.0,
        sizeChestCircumf: 63,
        sizeArmLength: 36,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "8 - 10 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 36,
        sizeArmCircumf: 23,
        sizeWristCircumf: 13.5,
        sizeChestCircumf: 68,
        sizeArmLength: 39,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "10 - 12 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 37,
        sizeArmCircumf: 24,
        sizeWristCircumf: 14.5,
        sizeChestCircumf: 74,
        sizeArmLength: 43,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
  ];
});

const sizeWomen = computed(() => {
  return [
    {
      key: "1 (" + t("sweater.chest_circumference") + " 75-80 cm)",
      value: {
        sizeNeckbandCircumf: 40.5,
        sizeArmCircumf: 26.0,
        sizeWristCircumf: 14,
        sizeChestCircumf: 77.5,
        sizeArmLength: 48.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "2 (" + t("sweater.chest_circumference") + " 80-85 cm)",
      value: {
        sizeNeckbandCircumf: 42,
        sizeArmCircumf: 26.0,
        sizeWristCircumf: 15,
        sizeChestCircumf: 82.5,
        sizeArmLength: 48.5,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "3 (" + t("sweater.chest_circumference") + " 85-90 cm)",
      value: {
        sizeNeckbandCircumf: 43,
        sizeArmCircumf: 27.5,
        sizeWristCircumf: 16,
        sizeChestCircumf: 87.5,
        sizeArmLength: 49.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "4 (" + t("sweater.chest_circumference") + " 90-95 cm)",
      value: {
        sizeNeckbandCircumf: 44,
        sizeArmCircumf: 29.0,
        sizeWristCircumf: 17,
        sizeChestCircumf: 92.5,
        sizeArmLength: 50.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "5 (" + t("sweater.chest_circumference") + " 95-100 cm)",
      value: {
        sizeNeckbandCircumf: 45.5,
        sizeArmCircumf: 31.0,
        sizeWristCircumf: 18,
        sizeChestCircumf: 97.5,
        sizeArmLength: 50.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "6 (" + t("sweater.chest_circumference") + " 100-110 cm)",
      value: {
        sizeNeckbandCircumf: 42.5,
        sizeArmCircumf: 32.0,
        sizeWristCircumf: 19,
        sizeChestCircumf: 105,
        sizeArmLength: 49.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "7 (" + t("sweater.chest_circumference") + " 110-120 cm)",
      value: {
        sizeNeckbandCircumf: 46.5,
        sizeArmCircumf: 34.0,
        sizeWristCircumf: 20,
        sizeChestCircumf: 115,
        sizeArmLength: 48.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "8 (" + t("sweater.chest_circumference") + " 120-130 cm)",
      value: {
        sizeNeckbandCircumf: 47.5,
        sizeArmCircumf: 36.0,
        sizeWristCircumf: 21,
        sizeChestCircumf: 125,
        sizeArmLength: 48.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "9 (" + t("sweater.chest_circumference") + " 130-140 cm)",
      value: {
        sizeNeckbandCircumf: 48,
        sizeArmCircumf: 38.0,
        sizeWristCircumf: 22,
        sizeChestCircumf: 135,
        sizeArmLength: 46.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "10 (" + t("sweater.chest_circumference") + " 140-150 cm)",
      value: {
        sizeNeckbandCircumf: 48.5,
        sizeArmCircumf: 40.0,
        sizeWristCircumf: 23,
        sizeChestCircumf: 145,
        sizeArmLength: 45,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
–
