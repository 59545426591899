<template>
  <select-field
    v-model="selectedItem"
    :label="$t('common.Size_category')"
    :placeholder="$t('common.Choose_a_size_category')"
    :items="persons"
    :disabled="false"
    :small="true"
    @item-selected="(item, oldItem) => onItemSelected(item, oldItem)"
  ></select-field>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";

import SelectField from "./../general/SelectField.vue";

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object,
  },
  includeGenderedOptions: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "itemSelected"]);

const selectedItem = ref(props.modelValue);

watch(
  () => props.modelValue,
  () => {
    selectedItem.value = props.modelValue;
  },
  { deep: true }
);

const onItemSelected = (item, oldItem) => {
  selectedItem.value = item;
  emit("update:modelValue", selectedItem.value);
  emit("itemSelected", selectedItem.value, oldItem);
};

const persons = computed(() => {
  return props.includeGenderedOptions
    ? [
        { key: t("common.Child"), value: "child" },
        // { key: t("common.Man"), value: "man" },
        { key: t("common.Woman"), value: "woman" },
        // { key: t("common.Unisex"), value: "unisex" },
        // { key: t("common.Custom_tailored"), value: "custom" },
      ]
    : [
        { key: t("common.Child"), value: "child" },
        { key: t("common.Grown_up"), value: "grown_up" },
        // { key: t("common.Custom_tailored"), value: "custom" },
      ];
});
</script>
